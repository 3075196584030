<template>
  <div>
    <!-- For Create -->
    <template v-if="!form._id">
      <template v-if="!patientForm.formIs">
        <!-- Loader Animation -->
        <loader
          v-if="searchPatient.loader"
          class="flex justify-center w-full"
        ></loader>
        <!-- End Loeader Animation -->

        <form
          v-on:submit.prevent="onCreate"
          class="divide-x-0 divide-y divide-gray-400 divide-solid"
        >
          <!-- Information Patient Selected -->
          <div
            class="px-2 py-2"
            v-if="
              searchPatient.userSelect && searchPatient.userSelect.firstName
            "
          >
            <div
              class="bg-dokBlue-lighter w-full px-2 py-5 flex flex-row gap-x-2"
            >
              <img
                :src="$checkAvatar(searchPatient.userSelect.image)"
                class="h-10 w-10 rounded-full"
              />
              <div class="flex flex-col w-full">
                <div class="flex flex-row items-center">
                  <h3 class="text-lg font-EffraR text-black flex-1">
                    {{
                      `${searchPatient.userSelect.firstName} ${searchPatient.userSelect.lastName}`
                    }}
                  </h3>
                  <img
                    src="/svg/x.svg"
                    class="cursor-pointer"
                    @click="unSelectUser()"
                  />
                </div>
                <span class="text-base text-gray-700 font-EffraR">
                  {{ searchPatient.userSelect.mobile }}
                </span>
              </div>
            </div>
          </div>
          <!-- End Information Patient Selected -->

          <!-- Search Patient Based By Algolia -->
          <div class="px-2 py-5 flex flex-col gap-y-5">
            <div
              class="flex flex-col gap-y-1 relative"
              v-if="
                !searchPatient.userSelect || !searchPatient.userSelect.firstName
              "
            >
              <label for="searchPatient-777" class="font-EffraR text-gray-700"
                >Trouvez le patient</label
              >
              <div class="relative">
                <input
                  type="text"
                  id="searchPatient-777"
                  class="
                    pl-3
                    pr-12
                    text-gray-800
                    bg-white
                    border-2 border-solid
                    rounded-cu
                    w-full
                    hover:border-gray-600
                    focus:outline-none
                    h-12
                    flex
                    items-center
                    justify-between
                    text-lg
                  "
                  :class="errors.patient ? 'border-red-500' : 'border-grayaaa'"
                  placeholder="Par nom, numéro de téléphone, CIN..."
                  v-model="searchPatient.value"
                  autocomplete="off"
                />

                <span
                  class="
                    absolute
                    right-0
                    top-0
                    h-full
                    flex
                    items-center
                    justify-center
                    px-2
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11.001 19c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zM21.001 21l-4.35-4.35"
                    />
                  </svg>
                </span>
              </div>

              <div
                v-if="!searchPatient.data.length && searchPatient.start"
                class="
                  bg-rosDok-ultra
                  text-white
                  font-EffraR
                  text-lg
                  p-2
                  rounded
                "
              >
                Il n'y aucun résultat
              </div>

              <div
                class="
                  absolute
                  left-0
                  w-full
                  mt-20
                  mb-5
                  space-y-2
                  bg-white
                  border border-gray-100
                  rounded-lg
                  overflow-y-scroll
                  scrollModal
                  z-5
                  flex flex-col
                  gap-y-5
                "
                style="
                  box-shadow: 0 8px 52px 0 rgba(0, 0, 0, 0.08);
                  border: solid 2px #aaaaaa;
                  max-height: 265px;
                "
                v-if="searchPatient.data.length"
              >
                <ul
                  class="
                    flex flex-col
                    divide-x-0 divide-y divide-gray-400 divide-solid
                  "
                >
                  <li
                    v-for="indice in searchPatient.data"
                    :key="indice.objectID"
                    class="
                      relative
                      flex flex-row
                      justify-between
                      items-center
                      gap-x-2
                      py-2
                      px-1
                      cursor-pointer
                    "
                    :class="
                      indice.objectID === form.patientId
                        ? 'bg-dokBlue-ultra'
                        : ''
                    "
                    @click="
                      () => {
                        form.patientId = indice.objectID;
                        searchPatient.userSelect = {
                          _id: indice.objectID,
                          firstName: indice.firstName,
                          lastName: indice.lastName,
                          mobile: indice.mobile,
                          image: indice.image,
                        };
                        searchPatient.value = '';
                        searchPatient.data = [];
                      }
                    "
                  >
                    <div class="flex-1 flex flex-row items-center gap-x-2">
                      <img
                        :src="$checkAvatar(indice.image)"
                        class="
                          h-8
                          w-h-8
                          rounded-full
                          border border-solid border-gray-500
                        "
                      />
                      <h5
                        class="font-EffraR"
                        :class="
                          indice.objectID === form.patientId
                            ? 'text-white'
                            : 'text-blackedsidebar'
                        "
                      >
                        {{ `${indice.firstName} ${indice.lastName}` }}
                      </h5>
                    </div>
                    <div
                      v-if="indice.mobile"
                      class="font-EffraR text-gray-600"
                      :class="
                        indice.objectID === form.patientId
                          ? 'text-white'
                          : 'text-gray-600'
                      "
                    >
                      {{ indice.mobile }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <button
              class="
                flex flex-row
                items-center
                justify-center
                gap-x-2
                rounded
                h-12
                border-0
                cursor-pointer
              "
              style="background: #eeeeee"
              @click="
                () => {
                  $performTracking({
                    data: {
                      id: $auth.getUserId(),
                      route: 'PATIENTEL',
                      action: 'NEW',
                      data: {},
                    },
                  });
                  patientForm.formIs = true;
                }
              "
              type="button"
            >
              <img src="/svg/user_plus.svg" />
              <span class="font-EffraR text-lg"
                >Ajouter un nouveau patient</span
              >
            </button>
          </div>
          <!-- End Search Patient Based By Algolia -->

          <!-- Motif Category -->
          <div class="px-2 py-5">
            <motif-dropdown
              :data="categories"
              :category.sync="form.categoryId"
              :custom-class="errors.motif ? 'border-red-500' : 'border-grayaaa'"
            ></motif-dropdown>
          </div>
          <!---------------------- End Motif Category -------------------------------->

          <!-- Date -->
          <div class="grid gap-4 grid-cols-2 py-5 px-2" v-if="form.categoryId">
            <div id="Date" class="flex flex-row items-start gap-x-2">
              <img src="/svg/calendar_sm.svg" />
              <div class="flex flex-col">
                <label for="le" class="text-gray-600 text-base font-EffraR"
                  >Le</label
                >
                <span
                  class="
                    relative
                    text-blackedsidebar text-lg
                    font-EffraR
                    capitalize
                  "
                  >{{ _moment()(form.startAt).format("dddd, DD MMMM") }}
                  <date-picker
                    :clearable="false"
                    format="DD-MM-YYYY"
                    @change="setDay"
                    :formatter="momentFormat"
                    id="le"
                    class="absolute inset-x-0 opacity-0 w-auto"
                /></span>
              </div>
            </div>
            <div id="Hours" class="flex flex-row items-start gap-x-2">
              <img src="/svg/clock_sm.svg" />
              <button
                class="
                  relative
                  dropdown:block
                  bg-transparent
                  border-none
                  flex flex-col
                  gap-y-1
                  w-full
                "
                role="navigation"
                aria-haspopup="true"
                type="button"
              >
                <label for="hour" class="text-gray-600 text-base font-EffraR"
                  >Horaire</label
                >
                <span class="relative text-blackedsidebar text-lg font-EffraR"
                  >{{ _moment()(form.startAt).format("HH:mm") }} -
                  {{
                    _moment()(form.startAt)
                      .add(motifSelected.duration, "minutes")
                      .format("HH:mm")
                  }}
                </span>
                <ul
                  class="
                    absolute
                    left-0
                    right-0
                    hidden
                    p-2
                    mt-5
                    space-y-2
                    text-sm
                    bg-white
                    border border-gray-100
                    rounded-lg
                    shadow-lg
                    max-h-300
                    overflow-y-scroll
                    scroll
                  "
                  aria-label="submenu"
                >
                  <div
                    class="grid gap-3 grid-cols-3"
                    v-if="availableHoursLoader === false"
                  >
                    <div
                      v-for="(hour, index) in availableHours"
                      :key="index"
                      class="
                        text-center
                        py-2
                        rounded-cu
                        border border-solid border-gray-300
                        shadow
                      "
                      :class="
                        hour.isAvailable
                          ? ` ${
                              _moment()(form.startAt).format('HH:mm') ===
                              hour.formatedHour
                                ? 'bg-dokBlue-ultra text-white cursor-pointer'
                                : 'bg-white text-dokBlue-ultra cursor-pointer'
                            }
                `
                          : 'bg-gray-200 text-gray-600 cursor-not-allowed'
                      "
                      @click="setHour(hour.formatedHour)"
                    >
                      <span class="block font-EffraR">
                        {{ hour.formatedHour }}</span
                      >
                    </div>
                  </div>
                </ul>
              </button>
            </div>
          </div>
          <!-- End Date -->

          <!-- Note Appointment-->
          <div class="flex flex-col py-5 px-2">
            <label for="note" class="font-EffraR text-gray-700">Note</label>
            <div class="mt-1">
              <textarea
                id="note"
                class="
                  w-full
                  py-3
                  px-1
                  font-EffraR
                  text-base text-gray-800
                  bg-white
                  border-2 border-solid border-grayaaa
                  rounded-cu
                  hover:border-gray-600
                  focus:outline-none
                  resize-none
                  h-100
                "
                placeholder="Note..."
                v-model="form.note"
              ></textarea>
            </div>
          </div>

          <!-- Button Save -->
          <div class="px-2 py-5">
            <button
              v-if="form.type !== 'RESERVED-SLOT'"
              type="submit"
              @click="onCreate"
              class="
                h-12
                flex
                items-center
                justify-center
                gap-x-2
                bg-dokBlue-ultra
                rounded-cu
                border-0
                w-full
                cursor-pointer
              "
            >
              <img src="/svg/calendar_md.svg" />
              <span class="text-lg font-EffraR text-white">Enregistrer</span>
            </button>
          </div>
        </form>

        <!---------------------- End Note Appointment-------------------------------->
      </template>

      <form-patient
        :data="patientForm"
        :on-sing-up="onCreatePatient"
        :set-birth-day="onPatientSetDate"
        :phone-error="patientForm.phoneError"
        :on-back="onCreatePatientBack"
        :phone-not-currect="patientForm.phoneNotCurrect"
        v-if="patientForm.formIs"
      ></form-patient>
    </template>

    <!-- For Update -->
    <div v-if="form._id && form.type !== 'RESERVED-SLOT'">
      <h5 class="font-EffraR text-2xl text-black p-3">Informations</h5>
      <div class="grid gap-y-2 grid-cols-2 gap-x-2 p-3">
        <div id="patient" class="flex flex-row items-start gap-x-2">
          <img src="/svg/usersm.svg" />
          <div class="flex flex-col">
            <label for="patient" class="text-gray-600 text-base font-EffraR"
              >Patient</label
            >
            <span class="relative text-blackedsidebar text-lg font-EffraR"
              >{{ `${form.patient_firstName} ${form.patient_lastName}` }}
            </span>
          </div>
        </div>
        <div id="status" class="flex flex-row items-start gap-x-2">
          <img src="/svg/calendar_sm.svg" />
          <div class="flex flex-col">
            <label for="status" class="text-gray-600 text-base font-EffraR"
              >Statut</label
            >
            <span
              class="
                relative
                text-blackedsidebar text-lg
                font-EffraR
                pr-8
                border border-solid border-gray-500
                px-1
                rounded
              "
              ><span v-html="$stateConsultation(form.state)"></span>
              <template>
                <label
                  for="formStatus"
                  class="absolute right-0 top-0 cursor-pointer mr-1"
                  ><i class="fas fa-angle-down text-black"></i
                ></label>
                <select
                  id="formStatus"
                  class="
                    absolute
                    left-0
                    top-0
                    w-full
                    rounded rounded-r-none
                    bg-transparent
                    border border-solid border-gray-300
                    font-EffraR
                    text-base
                    appearance-none
                    opacity-0
                  "
                  v-model="form.state"
                  @change="onUpdate('changeStatus')"
                >
                  <option value="IN-PROGRESS">En consultation</option>
                  <option value="IN-WAITING-ROOM">En salle d'attente</option>
                  <option value="JUSTIFIED-ABSENCE">Absence justifiée</option>
                  <option value="UNJUSTIFIED-ABSENCE">
                    Absence injustifiée
                  </option>
                  <option value="INDEFINITE">Indéfini</option>
                  <option value="DONE">Consultation terminée</option>
                </select>
              </template>
            </span>
          </div>
        </div>
        <div id="dateStart" class="flex flex-row items-start gap-x-2">
          <img src="/svg/calendar_sm.svg" />
          <div class="flex flex-col">
            <label for="dateStart" class="text-gray-600 text-base font-EffraR"
              >Date</label
            >
            <span class="relative text-blackedsidebar text-lg font-EffraR"
              >{{ _moment()(form.startAt).format("dddd, DD MMMM") }}
            </span>
          </div>
        </div>
        <div id="Phone" class="flex flex-row items-start gap-x-2">
          <img src="/svg/phone.svg" />
          <div class="flex flex-col">
            <label for="Phone" class="text-gray-600 text-base font-EffraR"
              >Téléphone</label
            >
            <span class="relative text-blackedsidebar text-lg font-EffraR"
              >{{ form.patient_mobile }}
            </span>
          </div>
        </div>
        <div id="Clock" class="flex flex-row items-start gap-x-2">
          <img src="/svg/clock.svg" />
          <div class="flex flex-col">
            <label for="Clock" class="text-gray-600 text-base font-EffraR"
              >Horaire</label
            >
            <span class="relative text-blackedsidebar text-lg font-EffraR"
              >{{ _moment()(form.startAt).format("HH:mm") }} -
              {{
                _moment()(form.startAt)
                  .add(form.category_duration, "minutes")
                  .format("HH:mm")
              }}
            </span>
          </div>
        </div>
        <div id="Email" class="flex flex-row items-start gap-x-2">
          <img src="/svg/mail.svg" />
          <div class="flex flex-col">
            <label for="Email" class="text-gray-600 text-base font-EffraR"
              >Email</label
            >
            <span
              class="relative text-blackedsidebar text-lg font-EffraR break-all"
              >{{ form.patient_email }}
            </span>
          </div>
        </div>
      </div>

      <!-- Appointmebnt Note -->
      <div class="mb-5 border-0 border-t border-solid border-gray-300 p-3">
        <label for="note">Note: </label>
        <div class="text-lg font-EffraR">
          {{ form.note || "--" }}
        </div>
      </div>
      <!---------------------- End Note Appontment -------------------------------->

      <!-- Actions Appointment -->
      <div class="grid gap-y-4 gap-x-2 grid-cols-2 mb-5 px-2">
        <button
          @click="onDeplace(form)"
          v-if="
            form.status === 'ACCEPTED' &&
            !_moment()(form.startAt).isBefore(new Date())
          "
          class="
            bg-transparent
            text-green-600
            border border-solid border-green-600
            py-2
            rounded
            font-EffraR
            w-full
            text-base
            px-1
            cursor-pointer
          "
        >
          Reporter le rendez-vous
        </button>
        <button
          @click="onChangeStatus(form._id, 'CANCELED')"
          v-if="
            form.status !== 'DONE' &&
            form.status !== 'CANCELED' &&
            !_moment()(form.startAt).isBefore(new Date())
          "
          class="
            bg-transparent
            text-red-600
            border border-solid border-red-600
            py-2
            rounded
            font-EffraR
            w-full
            text-base
            px-1
            cursor-pointer
          "
        >
          Annuler le rendez-vous
        </button>
        <button
          v-if="
            form.status === 'ACCEPTED' &&
            !_moment()(form.startAt).isBefore(new Date())
          "
          @click="$router.push(`/consultations/${form._id}`)"
          class="
            bg-transparent
            text-dokBlue-ultra
            border border-solid border-dokBlue-ultra
            py-2
            rounded
            font-EffraR
            w-full
            text-base
            px-1
            cursor-pointer
          "
        >
          Lancer la consultation
        </button>
        <button
          v-if="
            !form.doctor_has_access_helthbook ||
            (form.doctor_has_access_helthbook !== 'AUTHORIZED' &&
              form.doctor_has_access_helthbook !== 'PENDING')
          "
          @click="requestHealthbookAuthorization"
          class="
            bg-transparent
            text-dokBlue-ultra
            border border-solid border-dokBlue-ultra
            py-2
            rounded
            font-EffraR
            w-full
            text-base
            px-1
            cursor-pointer
          "
        >
          Demande d'accés au carnet de santé
        </button>
        <button
          @click="$router.push(`/patient/${form.patientId}`)"
          v-if="form.doctor_has_access_helthbook === 'AUTHORIZED'"
          class="
            bg-transparent
            text-dokBlue-ultra
            border border-solid border-dokBlue-ultra
            py-2
            rounded
            font-EffraR
            w-full
            text-base
            px-1
            cursor-pointer
          "
        >
          Voir le carnet de santé
        </button>
        <button
          v-if="form.doctor_has_access_helthbook === 'PENDING'"
          class="
            bg-transparent
            text-dokBlue-ultra
            border border-solid border-dokBlue-ultra
            py-2
            rounded
            font-EffraR
            w-full
            text-base
            px-1
            cursor-not-allowed
          "
        >
          Demande d'accès envoyée avec succès
        </button>
        <template
          v-if="
            form.status !== 'DONE' &&
            !_moment()(form.startAt).isAfter(new Date())
          "
        >
          <button
            @click="$router.push(`/consultations/${form._id}`)"
            class="
              bg-transparent
              text-dokBlue-ultra
              border border-solid border-dokBlue-ultra
              py-2
              rounded
              font-EffraR
              w-full
              text-base
              px-1
              cursor-pointer
            "
          >
            Voir la consultation
          </button>
        </template>
      </div>
      <!---------------------- End Actions Appointment -------------------------------->
    </div>

    <!-- Template for reserved slot -->
    <div class="p-5" v-if="form._id && form.type === 'RESERVED-SLOT'">
      <!-- Appointmebnt Note -->
      <div class="mb-3">
        <label for="note">Note: </label>
        <div class="text-lg font-EffraR">
          {{ form.note || "--" }}
        </div>
      </div>

      <button
        class="
          bg-transparent
          w-full
          border-0
          text-red-600
          font-EffraR
          cursor-pointer
          focus:outline-none
        "
        @click="onDelete"
      >
        Débloquer
      </button>
    </div>
  </div>
</template>

<script>
  import { find, uniqBy } from "lodash";
  import moment from "moment";
  import { EventBus } from "@/event-bus";
  import { mapActions } from "vuex";
  const loader = () => import("@/views/global-components/loader");
  const pickerDate = () => import("@/views/global-components/pickerDate");
  const formPatient = () => import("./createPatient");
  // const formPatient = () => import("@views/dashboard/clients/AddPatient");
  const motifDropdown = () => import("./components/motifsDrop");

  // Google Check Phone
  const phoneUtil =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const PNF = require("google-libphonenumber").PhoneNumberFormat;

  // Algolia
  import algoliasearch from "algoliasearch/lite";

  // Date Picker
  import DatePicker from "vue2-datepicker";
  import { fr } from "vue2-datepicker/locale/fr";
  import "vue2-datepicker/index.css";

  export default {
    props: {
      form: {
        type: Object,
        required: true,
      },
    },
    computed: {
      categories() {
        let array = [];
        // console.log(this.form.startAt);
        let slotByDay = find(
          this.$store.state.agenda.slots,
          (item) => item.day === this.form.startAt.day()
        );
        if (slotByDay && slotByDay.slots.length) {
          slotByDay.slots.forEach((slot) => {
            if (slot.categories.length) {
              slot.categories.forEach((cat) => {
                array.push(cat);
              });
            }
          });
        }
        return array.length
          ? uniqBy(array, "_id")
          : this.$store.getters["agenda/categories"];
      },
      motifSelected() {
        if (this.form.categoryId)
          return find(this.categories, (cat) => cat._id === this.form.categoryId);

        return {};
      },
      events: {
        get() {
          return this.$store.state.agenda.eventsAgenda;
        },
        set() {
          //console.log("Events Updated");
        },
      },
    },
    data() {
      return {
        searchClient: algoliasearch(
          "2BJUOM1BDB",
          "7906f008c0346b78f39d051fa293387f"
        ),
        DatePickerLocal: fr,
        momentFormat: {
          stringify: (date) => {
            return moment(date).toDate();
          },
        },
        searchPatient: {
          start: false,
          loader: false,
          value: null,
          data: [],
        },
        availableHoursLoader: "String",
        availableHours: [],
        patientForm: {
          formIs: false,
          phoneError: false,
          phoneNotCurrect: false,
          birthday: moment("Dec 25, 1995"),
        },
        errors: {
          motif: false,
          patient: false,
        },
      };
    },
    watch: {
      "form.startAt": function (newVal, oldVal) {
        // if (newVal !== oldVal) this.generateHours();
      },
      "form.categoryId": function (newVal, oldVal) {
        if (newVal !== oldVal) this.generateHours();
      },
      "searchPatient.value": function (newVal, oldVal) {
        if (newVal.length < 1) {
          this.searchPatient.start = false;
        }
        if (newVal !== oldVal && newVal.length > 3) {
          this.searchPatient.data = [];
          this.onSearchPatient();
        }
      },
    },
    methods: {
      setDay(day) {
        this.form.startAt = moment(day);
      },
      ...mapActions("agenda", [
        "CREATE_EVENT",
        "UPDATE_EVENT",
        "GENERATED_HOURS",
        "UPDATE_STATUS_EVENT",
        "DELETE_EVENT",
      ]),
      ...mapActions("doctor", ["CREATE_PATIENT"]),
      ...mapActions({
        requestAuthorization: "authorization/REQUEST_AUTHORIZATION",
      }),
      unSelectUser() {
        this.form.patientId = null;
        this.searchPatient.userSelect = null;
        this.$forceUpdate();
      },
      // Sent Request Access healthbook
      requestHealthbookAuthorization() {
        // If Doesn't exist patient
        if (!this.form.patientId) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message:
              "Il y a un problème de traitement des données, veuillez revenir plus tard",
            timerAlert: 8000,
          });
        }
        this.$vs.loading();
        this.requestAuthorization({
          patientId: this.form.patientId,
          authScope: "HEALTH-BOOK",
          onData: ({ message, ok }) => {
            this.$vs.loading.close();
            this.$vs.notify({
              time: 8000,
              title: "",
              text: ok ? "Demande d'accès envoyée avec succès" : "Error",
              color: ok ? "success" : "error",
              iconPack: "feather",
              icon: "icon-check",
            });
            if (ok) {
              this.form.doctor_has_access_helthbook = "PENDING";
            }
          },
        });
      },
      // Set Type Appointment
      async generateHours() {
        if (this.form.categoryId === null || !this.form.startAt.isValid()) {
          return;
        }
        this.availableHoursLoader = "LOADING";
        this.$vs.loading();

        const { data, ok } = await this.GENERATED_HOURS({
          date: this.form.startAt.format("DD/MM/YYYY"),
          category: this.form.categoryId,
        });

        if (ok) {
          let availableHours = find(data, (item) => {
            return (
              moment(item.hourDate).isSame(this.form.startAt, "day") &&
              this.form.startAt.format("HH:mm") ===
                moment(item.formatedHour, "HH:mm").format("HH:mm")
            );
          });

          if (!availableHours) {
            let choiceAutoHours = find(data, (item) => {
              return (
                item.isAvailable &&
                !item.isOld &&
                moment(item.hourDate).isAfter(this.form.startAt, "minutes")
              );
            });
            this.form.startAt = moment(choiceAutoHours.hourDate);
          }

          if (availableHours && !availableHours.isAvailable) {
            let choiceAutoHours = find(data, (item) => {
              return (
                item.isAvailable &&
                !item.isOld &&
                moment(item.hourDate).isAfter(this.form.startAt, "minutes")
              );
            });

            if (choiceAutoHours) {
              this.form.startAt = moment(choiceAutoHours.hourDate);
              EventBus.$emit("flashDok", {
                type: "errorr",
                message: `Le créneau choisi empiète sur un rendez-vous réservé. La prochaine plage horaire disponible pour ce motif commence à ${this.form.startAt.format(
                  "HH:mm"
                )}.`,
                timerAlert: 4000,
              });
            }
          }

          this.availableHours = data;
          this.availableHoursLoader = false;
          this.$vs.loading.close();
        }
      },

      // Create New Appointment
      async onCreate() {
        event.preventDefault();
        if (!this.form.patientId) {
          this.errors.patient = true;
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Merci de vérifier le champ patient",
            timerAlert: 8000,
          });
        }
        if (!this.form.categoryId) {
          this.errors.motif = true;
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Merci de vérifier le champ motif",
            timerAlert: 8000,
          });
        }

        this.errors.motif = false;
        this.errors.patient = false;

        const { data, ok } = await this.CREATE_EVENT({ form: this.form });
        if (ok) {
          this.$performTracking({
            data: {
              id: this.$auth.getUserId(),
              route: "APPOINTEMENT",
              action: "CREATE",
              data: { _id: this.form.patientId || null },
            },
          });
          EventBus.$emit("Modal_Has_New_Success_Appointment", data);
        }
      },

      // Update Appointment
      async onUpdate(type = null) {
        this.$vs.loading();
        const { data, ok } = await this.UPDATE_EVENT(this.form);
        if (type === "changeStatus") {
          this.$vs.loading.close();
          // //console.log("xx");
          if (ok) {
            return EventBus.$emit("flashDok", {
              type: "success",
              message: "Le statut du rendez-vous a été mis à jour avec succès!",
              timerAlert: 8000,
            });
          }
        }
        if (ok) {
          this.$vs.loading.close();
          EventBus.$emit("Modal_Has_Update_Success_Appointment", data);
        }
      },

      //--------------------------------------Patient Functions-------------------------------------------------//
      // Search For Patient
      async onSearchPatient() {
        if (!this.searchPatient.value) return;
        let index_search = this.searchClient.initIndex("dokma_patients");

        index_search.search(this.searchPatient.value).then(({ hits }) => {
          if (hits.length) {
            this.searchPatient.data = hits;
            this.searchPatient.start = false;
            return;
          }

          this.searchPatient.start = true;
        });
      },
      // Create New Patient
      CreatePatient() {
        this.patientForm.formIs = true;
      },
      onPatientSetDate(date) {
        this.patientForm.birthday = date;
      },
      onCreatePatientBack() {
        this.patientForm.formIs = false;
      },
      // On Create Patient
      async onCreatePatient() {
        if (!this.patientForm.mobile && !this.patientForm.phoneError) {
          this.patientForm.phoneError = true;
          return;
        }
        const number = phoneUtil.parseAndKeepRawInput(
          this.patientForm.mobile,
          "MA"
        );
        if (!phoneUtil.isValidNumber(number)) {
          this.patientForm.phoneError = true;
          this.patientForm.phoneNotCurrect = true;
          return;
        }
        this.patientForm.phoneError = false;
        this.patientForm.phoneNotCurrect = false;
        this.patientForm.role = "PATIENT";
        this.$vs.loading({
          scale: 0.45,
        });
        const { data } = await this.CREATE_PATIENT({ ...this.patientForm });
        this.$vs.loading.close();
        if (!data.ok) {
          return EventBus.$emit("flashDok", {
            defualtTime: 30000,
            type: "errorr",
            message: data.message,
          });
        }

        if (data.ok) {
          EventBus.$emit("on_success_add_new_patient", data.data);

          this.searchPatient.userSelect = {
            _id: data.data._id,
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            image: data.data.image,
          };

          this.patientForm.formIs = false;
          this.form.patientId = data._id;
          this.$vs.loading.close();

          this.$performTracking({
            data: {
              id: this.$auth.getUserId(),
              route: "PATIENTEL",
              action: "CREATE",
              data: { _id: data._id || null },
            },
          });

          return EventBus.$emit("flashDok", {
            defualtTime: 30000,
            type: "success",
            message: "Patient Créé avec succès",
          });
        }
      },
      //---------------------------------------------Patient End Functions----------------------------------------------------------//
      // Change status
      async onChangeStatus(_id, type) {
        if (type === "CANCELED") {
          EventBus.$emit("flashDok", {
            defualtTime: 30000,
            type: "delete",
            message:
              "Si vous supprimez ce rendez-vous, vous le perdrez définitivement et n'y aurez plus jamais accès.",
            successmessage: "Rendez-vous supprimé avec succès",
            confirm: () => {
              return this.changeStatusRequest(_id, type);
            },
            cancel: (callback) => {
              return callback();
            },
          });

          return;
        }

        const { data, ok } = this.changeStatusRequest(_id, type);
        if (ok) {
          EventBus.$emit("Modal_Close", true);
          this.$vs.notify({
            time: 4000,
            text: `Appointment Success Change Status : ${type}`,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
      },
      async changeStatusRequest(_id, type) {
        const data = await this.UPDATE_STATUS_EVENT({
          _id,
          status: type,
        });

        return data;
      },
      // Emite Event Deplacer
      onDeplace(item) {
        if (item._id) {
          EventBus.$emit("Modal_Close");
          EventBus.$emit("ProposEvent", item);
        }
      },
      // Delete Event
      async onDelete() {
        if (!this.form._id) return;
        const { ok } = await this.DELETE_EVENT({
          _id: this.form._id,
        });

        if (!ok) return alert("Il y a un problème avec la suppression");

        if (ok) {
          EventBus.$emit("EVENT_DELETE", this.form._id);
        }
      },
      // Set Hour
      setHour(hour) {
        let formatHour = moment(hour, "HH:mm");
        // console.log(this.form.startAt);
        if (formatHour.isValid()) {
          // //console.log(formatHour);
          this.form.startAt.set({
            h: formatHour.format("HH"),
            m: formatHour.format("mm"),
          });

          this.$forceUpdate();
        }
      },
      // Moment
      _moment() {
        return moment;
      },
      // lodash
      lodash_() {
        return _;
      },
    },
    components: { loader, pickerDate, formPatient, motifDropdown, DatePicker },
    beforeDestroy() {
      this.DatePickerLocal = fr;

      this.momentFormat = {
        stringify: (date) => {
          return moment(date).toDate();
        },
      };
      this.searchPatient = {
        start: false,
        loader: false,
        value: null,
        data: [],
        userSelect: null,
      };

      this.availableHoursLoader = "String";
      this.availableHours = [];
      this.patientForm = {
        formIs: false,
        phoneError: false,
        phoneNotCurrect: false,
        birthday: moment("Dec 25, 1995"),
      };

      EventBus.$emit("BACK_TO_OLD_FORM");
    },
  };
</script>

<style>
label {
  @apply font-EffraM text-gray-600 text-base;
}
</style>
